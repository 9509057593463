/* eslint-disable */

import Vue from "vue";
import Vuelidate from "vuelidate";
import VueMoment from "vue-moment";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VuetifySnackbarQueue from "vuetify-snackbar-queue";
import i18n from "./plugins/i18n";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import VueHtmlToPaper from "vue-html-to-paper";
import LoadScript from "vue-plugin-load-script";
import Plausible from "plausible-tracker";
import { createHead } from "@unhead/vue";
import { UnheadPlugin } from "@unhead/vue/dist/vue2";

if (location.host === "alge-results.com") {
  const { enableAutoPageviews } = Plausible({
    domain: "alge-results.com",
    hashMode: true
  });
  enableAutoPageviews();
}

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn:
      "https://5a8c8444a3b84d81b5e76b5f02784a36@o514291.ingest.sentry.io/5617374",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          "localhost",
          "dev.alge-results.com",
          "alge-results.com",
          /^\//
        ]
      })
    ],
    tracesSampleRate: 0.2,
    attachProps: true,
    logErrors: true,
    release: `${process.env.PACKAGE_NAME}@${process.env.PACKAGE_VERSION}`,
    environment: location.host.includes("dev.") ? "development" : "production"
  });
}

const head = createHead();
Vue.use(UnheadPlugin);

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(VueMoment);
Vue.use(VuetifySnackbarQueue);
Vue.use(VueHtmlToPaper, {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://cdn.jsdelivr.net/npm/@mdi/font@6.x/css/materialdesignicons.min.css",
    "https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css",
    "./print.css"
  ]
});
Vue.use(LoadScript);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  unhead: head,
  render: h => h(App)
}).$mount("#root");
