<template>
  <v-footer tile color="white" class="mt-8 d-flex align-start">
    <v-container class="px-4 px-md-12">
      <v-row>
        <v-col cols="12" sm="4" class="d-sm-flex justify-center">
          <div>
            <span class="columnTitle">
              {{ $t("footer.copyright") }}<span class="columnLine"></span>
            </span>
            <ul class="columnList">
              <li>
                &copy; {{ new Date().getFullYear() }}
                <a :href="corporateUrl" title="ALGE-TIMING" target="_blank"
                  >ALGE-TIMING</a
                >
              </li>
            </ul>
          </div>
        </v-col>

        <v-col cols="12" sm="4" class="d-sm-flex justify-center">
          <div>
            <span class="columnTitle">
              {{ $t("footer.legal") }}<span class="columnLine"></span>
            </span>
            <ul class="columnList">
              <li>
                <a :href="imprintUrl" title="Imprint" target="_blank">{{
                  $t("footer.imprint")
                }}</a>
              </li>
              <li>
                <a
                  :href="privacyPolicy"
                  :title="$t('footer.privacyPolicy')"
                  target="_blank"
                  >{{ $t("footer.privacyPolicy") }}</a
                >
              </li>
              <li v-if="hasTimingPointsAccount">
                <a
                  :href="contractAgreement"
                  :title="$t('footer.contractAgreement')"
                  target="_blank"
                  >{{ $t("footer.contractAgreement") }}</a
                >
              </li>
            </ul>
          </div>
        </v-col>

        <v-col cols="12" sm="4" class="d-sm-flex justify-center">
          <div>
            <span class="columnTitle">
              {{ $t("footer.application") }}<span class="columnLine"></span>
            </span>
            <ul class="columnList">
              <li>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">v {{ version }}</span>
                  </template>
                  <span>{{ $d(timestamp, "shortDateTime") }}</span>
                </v-tooltip>
              </li>
              <li>
                <router-link :to="{ name: 'Changes' }">
                  {{ $t("footer.changes") }}
                </router-link>
              </li>
              <li v-if="isAdmin">
                <router-link :to="{ name: 'Status' }">
                  {{ $t("footer.status") }}
                </router-link>
              </li>
            </ul>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TheFooter",
  data() {
    return {
      imprintUrl:
        "https://firmen.wko.at/WEB/DetailsKontakt.aspx?FirmaID=1e7d6a11-1536-4e0a-9263-6ffdd0d766b0",
      corporateUrl: "https://www.alge-timing.com/",
      privacyPolicies: [
        require("./../assets/documents/data-privacy-statement_alge-results.pdf"),
        require("./../assets/documents/Datenschutzerklaerung_alge-results.pdf")
      ],
      contractAgreements: [
        require("./../assets/documents/Vereinbarung-Auftragsverarbeitung_alge-results.pdf")
      ]
    };
  },
  computed: {
    ...mapGetters({
      hasTimingPointsAccount: "user/hasTimingPointsAccount",
      isAdmin: "user/isAdmin"
    }),
    timestamp() {
      return process.env.BUILD_TIMESTAMP ? process.env.BUILD_TIMESTAMP : "";
    },
    version() {
      return process.env.PACKAGE_VERSION ? process.env.PACKAGE_VERSION : "";
    },
    privacyPolicy() {
      switch (String(this.$i18n.locale).toLowerCase()) {
        case "de":
          return this.privacyPolicies[1];
        default:
          return this.privacyPolicies[0];
      }
    },
    contractAgreement() {
      switch (String(this.$i18n.locale).toLowerCase()) {
        default:
          return this.contractAgreements[0];
      }
    }
  }
};
</script>
<style scoped>
.columnTitle {
  display: inline-block;
  text-align: left;
  font-weight: bold;
  text-transform: uppercase;
}
.columnLine {
  display: block;
  width: 30px;
  height: 2px;
  background-color: #212121;
  margin: 2px 0 10px 0;
}
.columnList {
  list-style-type: none;
  padding: 0;
}
</style>
