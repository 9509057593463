<template>
  <v-dialog v-model="showDialog" width="600">
    <template v-slot:activator="{ on }">
      <v-btn text v-on="on">
        {{ $i18n.locale }}
      </v-btn>
    </template>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          {{ $t("forms.language.title") }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="showDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-container>
        <v-row>
          <v-col
            v-for="language in languagesSorted"
            :key="language.locale"
            cols="12"
            sm="6"
            md="4"
          >
            <v-btn @click="languageSelected(language.locale)" text>
              <v-avatar size="30" tile>
                <v-img
                  :src="getLanguageImageUrl(language.locale)"
                  :alt="language.translation"
                  contain
                ></v-img>
              </v-avatar>

              <span class="ml-2">
                {{ language.translation }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapMutations } from "vuex";
import languages from "@/utils/languages";

export default {
  name: "TheLanguageSwitcher",
  data() {
    return {
      showDialog: false,
      baseURL: process.env.VUE_APP_BASE_URL
    };
  },
  computed: {
    languages() {
      return this.$i18n.availableLocales;
    },
    languagesSorted() {
      return this.languages
        .map(language => ({
          locale: language,
          translation: this.$i18n.t(`languages.${language}`)
        }))
        .sort((a, b) => {
          if (a.translation > b.translation) return 1;
          if (a.translation < b.translation) return -1;
          return 0;
        });
    }
  },
  methods: {
    ...mapMutations({
      setLocale: "settings/setLocale"
    }),
    getLanguageImageUrl(language) {
      return languages[language]
        ? `${this.baseURL}/${languages[language].imageUrl}`
        : "";
    },
    languageSelected(language) {
      this.showDialog = false;
      this.$i18n.locale = language;
      this.$vuetify.lang.current = language;
      document.querySelector("html").setAttribute("lang", language);
      this.setLocale(language);
    }
  }
};
</script>
