<template>
  <v-alert
    v-if="show"
    tile
    :color="alert.color"
    :class="['ma-0', 'text-center', `${alert.textColor}--text`]"
  >
    {{ $t(alert.text) }}
  </v-alert>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TheConnectionStatus",
  data() {
    return {
      show: false
    };
  },
  computed: {
    ...mapGetters({
      isReconnecting: "socket/isReconnecting"
    }),
    alert() {
      if (this.isReconnecting) {
        return {
          color: "#FFEAEA",
          text: "connectionStatus.error",
          textColor: "error"
        };
      } else {
        return {
          color: "#E5F1E6",
          text: "connectionStatus.success",
          textColor: "success"
        };
      }
    }
  },
  watch: {
    isReconnecting(value) {
      if (value) {
        this.show = true;
      } else {
        const self = this;
        setTimeout(function() {
          self.show = false;
        }, 3000);
      }
    }
  }
};
</script>
