/* eslint-disable */

import Repository from "../api/repository";
import i18n from "../../plugins/i18n";

const resource = "/nations";

export default {
  namespaced: true,
  state: {
    items: null
  },
  getters: {
    getItemById: state => id => {
      if (state.items) {
        const item = state.items.find(item => item.id === id);
        return item === undefined ? null : item;
      }

      return null;
    },
    getItemByIso2: state => iso2 => {
      if (state.items) {
        const item = state.items.find(item => item.iso2 === iso2);
        return item !== undefined ? item : null;
      }

      return null;
    },
    getItemByIso3: state => iso3 => {
      if (state.items) {
        const item = state.items.find(item => item.iso3 === iso3);
        return item !== undefined ? item : null;
      }

      return null;
    },
    getItemByIoc3: state => ioc3 => {
      if (state.items) {
        const item = state.items.find(item => item.ioc3 === ioc3);
        return item !== undefined ? item : null;
      }

      return null;
    },
    getItemByValue: state => value => {
      const items = state.items.filter(el => el.iso2 === value || el.iso3 === value || el.ioc3 === value)

      if (items.length > 0) {
        return items[0];
      }

      return null;
    },
    getItemsByLang: state => {
      if (!state.items) return [];

      let items = state.items.map(nation => ({
        text: i18n.t(`countries.${nation.id}`),
        value: nation.id
      }));

      items = items.sort((a, b) => {
        if (a.text > b.text) return 1;
        if (a.text < b.text) return -1;
        return 0;
      });

      return items;
    },
    isNation: state => value => state.items.filter(el => el.iso2 === value || el.iso3 === value || el.ioc3 === value).length > 0
  },
  mutations: {
    setItems(state, nations) {
      state.items = nations;
    }
  },
  actions: {
    fetchItems({ commit }) {
      return new Promise((resolve, reject) => {
        Repository.get(`${resource}/all`)
          .then(response => {
            commit("setItems", response.data.data);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};
