<template>
  <v-alert
    v-if="devicesWithWarning.length > 0"
    tile
    :color="alert.color"
    class="ma-0 text-center warning--text"
  >
    {{
      $tc("deviceWarning", devicesWithWarning.length, { devices: warningText })
    }}
  </v-alert>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TheDeviceWarnings",
  data() {
    return {
      alert: {
        color: "#FFF1E1"
      }
    };
  },
  computed: {
    ...mapState({
      devices: state => state.devices.items
    }),
    devicesWithWarning() {
      if (this.devices && this.devices.length > 0) {
        return this.devices.filter(el => {
          const channels = el.deviceComponents.ChannelState;
          const keys = Object.keys(channels);

          for (let i = 0; i < keys.length; i++) {
            if (channels[keys[i]]) {
              return true;
            }
          }

          return false;
        });
      }

      return new Array();
    },
    warningText() {
      return this.devicesWithWarning
        .map(el => {
          const deviceName = el.deviceComponents.AppSettings.name;

          if (deviceName) {
            return `${el.id} (${deviceName})`;
          }

          return `${el.id}`;
        })
        .join(", ");
    }
  }
};
</script>
