import Vue from "vue";
import Vuex from "vuex";

import events from "./modules/events";
import devices from "./modules/devices";
import nations from "./modules/nations";
import settings from "./modules/settings";
import socket from "./modules/socket";
import time from "./modules/time";
import timingpoints from "./modules/timingpoints";
import trigger from "./modules/trigger";
import user from "./modules/user";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    events,
    devices,
    nations,
    settings,
    socket,
    time,
    timingpoints,
    trigger,
    user
  }
});
