/* eslint-disable */

import Vue from "vue";
import Repository from "../api/repository";

const resource = "/events";

export default {
  namespaced: true,
  state: {
    items: [],
    selectedItem: null,
    selectedItemLogItems: [],
    selectedItemTrigger: null,
    selectedItemTriggerTimestampCollection: null,
    selectedItemHeatRunCollection: null,
    selectedItemClassFilter: {
      value: {
        field: "general",
        value: "all"
      }
    },
    triggerAutoUpdate: true,
    openItems: [],
    selectedOpenItem: null,
    itemsByUser: [],
    selectedItemByUser: null
  },
  getters: {
    getCompetitorById: state => id => {
      const competitor = state.selectedItem.competitors.find(
        item => item.id === id
      );

      if (competitor !== undefined) {
        const lastName = competitor.userData.lastName;
        const firstName = competitor.userData.firstName;

        competitor.userData.name = "";
        if (lastName) {
          competitor.userData.name = lastName;
        }
        if (lastName && firstName) {
          competitor.userData.name += " ";
        }
        if (firstName) {
          competitor.userData.name += firstName;
        }

        return competitor;
      }

      return null;
    },
    getCompetitorByUserId: state => userId => {
      const competitor = state.selectedItem.competitors.find(
        item => item.userId === userId
      );

      if (competitor !== undefined) {
        const lastName = competitor.userData.lastName;
        const firstName = competitor.userData.firstName;

        competitor.userData.name = "";
        if (lastName) {
          competitor.userData.name = lastName;
        }
        if (lastName && firstName) {
          competitor.userData.name += " ";
        }
        if (firstName) {
          competitor.userData.name += firstName;
        }

        return competitor;
      }

      return null;
    },
    getCompetitorByStartNumber: state => startNumber => {
      const competitor = state.selectedItem.competitors.find(
        item => item.startNumber === startNumber
      );

      if (competitor !== undefined) {
        const lastName = competitor.userData.lastName;
        const firstName = competitor.userData.firstName;

        competitor.userData.name = "";
        if (lastName) {
          competitor.userData.name = lastName;
        }
        if (lastName && firstName) {
          competitor.userData.name += " ";
        }
        if (firstName) {
          competitor.userData.name += firstName;
        }

        return competitor;
      }

      return null;
    },
    getCompetitors: state => {
      if (state.selectedItem) {
        return state.selectedItem.competitors.map(competitor => {
          const lastName = competitor.userData.lastName;
          const firstName = competitor.userData.firstName;

          competitor.userData.name = lastName ? lastName : "";
          competitor.userData.name += firstName ? " " + firstName : "";
        });
      }

      return [];
    },
    isTriggerUsed: state => timestamp => {
      return state.selectedItemTriggerTimestampCollection === null
        ? false
        : state.selectedItemTriggerTimestampCollection.has(timestamp);
    },
    getStartNumbersOfUsedTrigger: state => timestamp => {
      return state.selectedItemTriggerTimestampCollection === null
        ? new Array()
        : state.selectedItemTriggerTimestampCollection.get(timestamp);
    }
  },
  mutations: {
    updateSelectedItemClassFilter(state, value) {
      state.selectedItemClassFilter = value;
    },
    setItems(state, events) {
      state.items = events;
    },
    setSelectedItem(state, event) {
      state.selectedItem = {
        ...event,
        viewers:
          state.selectedItem && state.selectedItem.viewers
            ? state.selectedItem.viewers
            : 0
      };
    },
    clearSelectedItem(state) {
      state.selectedItem = null;
    },
    setOpenItems(state, events) {
      state.openItems = events;
    },
    setSelectedOpenItem(state, event) {
      state.selectedOpenItem = event;
    },
    addItemToItems(state, event) {
      state.items.push(event);
    },
    addItemsToItems(state, events) {
      state.items = state.items.concat(events);
    },
    setItemsByUser(state, events) {
      state.itemsByUser = events;
    },
    setSelectedItemByUser(state, event) {
      state.selectedItemByUser = event;
    },
    addItemToItemsByUser(state, event) {
      state.itemsByUser.push(event);
    },
    addCompetitorToItem(state, eventId, competitor) {
      const index = state.items.findIndex(el => el.id === eventId);
      state.items[index].competitors.push(competitor);
    },
    addCompetitorToSelectedItem(state, competitor) {
      state.selectedItem.competitors.push(competitor);
    },
    setCompetitorsOfSelectedItem(state, competitors) {
      state.selectedItem.competitors = competitors;
    },
    addCompetitorsToSelectedItem(state, competitors) {
      state.selectedItem.competitors = state.selectedItem.competitors.concat(
        competitors
      );
    },
    updateCompetitorOfSelectedItem(state, competitor) {
      const index = state.selectedItem.competitors.findIndex(
        el => el.id === competitor.id
      );
      Vue.set(state.selectedItem.competitors, index, competitor);
    },
    setSelectedItemHeat(state, heat) {
      const index = state.selectedItem.heats.findIndex(el => el.id === heat.id);
      Vue.set(state.selectedItem.heats, index, heat);
    },
    setRankingOfSelectedItemHeat(state, heatRanking) {
      const index = state.selectedItem.heats.findIndex(
        el => el.id === heatRanking.heatId
      );
      if (index !== -1) {
        state.selectedItem.heats[index].ranking = heatRanking;
      }
    },
    setHeatCycleOfSelectedItemHeat(state, payload) {
      const index = state.selectedItem.heats.findIndex(
        el => el.id === payload.heatId
      );
      state.selectedItem.heats[index].heatCycle.entries = payload.heatCycle;
    },
    setRankingOfSelectedItem(state, overallRanking) {
      state.selectedItem.overallRanking = overallRanking;
    },
    removeItemFromItemsByUser(state, eventId) {
      const index = state.itemsByUser.findIndex(el => el.id === eventId);
      state.itemsByUser.splice(index, 1);
    },
    removeCompetitorFromSelectedItem(state, competitorId) {
      const index = state.selectedItem.competitors.findIndex(
        el => el.id === competitorId
      );
      state.selectedItem.competitors.splice(index, 1);
    },
    removeCompetitorFromSelectedItemHeat(state, payload) {
      const heatIndex = state.selectedItem.heats.findIndex(
        el => el.id === payload.heatId
      );
      const competitorIndex = state.selectedItem.heats[
        heatIndex
      ].competitors.findIndex(el => el === payload.competitorId);
      state.selectedItem.heats[heatIndex].competitors.splice(
        competitorIndex,
        1
      );
    },
    setSelectedItemHeatCompetitors(state, payload) {
      const index = state.selectedItem.heats.findIndex(
        el => el.id === payload.heatId
      );
      state.selectedItem.heats[index].competitors = payload.competitors;
    },
    updateItemInItemsByUser(state, event) {
      const index = state.itemsByUser.findIndex(el => el.id === event.id);
      Vue.set(state.itemsByUser, index, event);
    },
    updateRunOfSelectedItemHeat(state, payload) {
      const heatIndex = state.selectedItem.heats.findIndex(
        el => el.id === payload.heatId
      );
      const runIndex = state.selectedItem.heats[
        heatIndex
      ].heatCycle.entries.findIndex(el => el.runId === payload.run.runId);
      Vue.set(
        state.selectedItem.heats[heatIndex].heatCycle.entries,
        runIndex,
        payload.run
      );
    },
    removeRunOfSelectedItemHeat(state, payload) {
      const heatIndex = state.selectedItem.heats.findIndex(
        el => el.id === payload.heatId
      );
      const runIndex = state.selectedItem.heats[
        heatIndex
      ].heatCycle.entries.findIndex(el => el.runId === payload.run.runId);
      Vue.delete(
        state.selectedItem.heats[heatIndex].heatCycle.entries,
        runIndex
      );
    },
    addRunToSelectedItemHeat(state, payload) {
      const heatIndex = state.selectedItem.heats.findIndex(
        el => el.id === payload.heatId
      );

      state.selectedItem.heats[heatIndex].heatCycle.entries.push(payload.run);
    },
    addRunsToSelectedItemHeats(state, runs) {
      const heatMap = new Map();
      state.selectedItem.heats.map((heat, index) =>
        heatMap.set(heat.id, index)
      );

      runs.map(run => {
        state.selectedItem.heats[
          heatMap.get(run.heatId)
        ].heatCycle.entries.push(run);
      });
    },
    updateTimingSetupOfSelectedItem(state, timingSetup) {
      state.selectedItem.setup = timingSetup;
    },
    addDocumentToSelectedItem(state, document) {
      state.selectedItem.documents.push(document);
    },
    updateDocumentOfSelectedItem(state, document) {
      const documentIndex = state.selectedItem.documents.findIndex(
        el => el.id === document.id
      );

      if (documentIndex > -1) {
        Vue.set(state.selectedItem.documents, documentIndex, document);
      }
    },
    removeDocumentFromSelectedItem(state, document) {
      const documentIndex = state.selectedItem.documents.findIndex(
        el => el.id === document.id
      );

      if (documentIndex > -1) {
        Vue.delete(state.selectedItem.documents, documentIndex);
      }
    },
    updateViewerOfSelectedItem(state, viewers) {
      state.selectedItem.viewers = viewers;
    },
    setLogItems(state, log) {
      state.selectedItemLogItems = log;
    },
    addLogItem(state, item) {
      state.selectedItemLogItems.unshift(item);
    },
    updateLogItem(state, item) {
      const itemIndex = state.selectedItemLogItems.findIndex(
        el => el.id === item.id
      );

      if (itemIndex > -1) {
        Vue.set(state.selectedItemLogItems, itemIndex, item);
      }
    },
    removeLogItem(state, item) {
      const itemIndex = state.selectedItemLogItems.findIndex(
        el => el.id === item.id
      );

      if (itemIndex > -1) {
        Vue.delete(state.selectedItemLogItems, itemIndex);
      }
    },
    setSelectedItemTrigger(state, trigger) {
      state.selectedItemTrigger = trigger;
    },
    addTriggerToSelectedItemTrigger(state, trigger) {
      if (state.selectedItemTrigger === null) {
        state.selectedItemTrigger = [];
      }

      state.selectedItemTrigger.unshift(trigger);
    },
    clearSelectedItemTrigger(state) {
      state.selectedItemTrigger = [];
    },
    toggleTriggerAutoUpdate(state) {
      state.triggerAutoUpdate = !state.triggerAutoUpdate;
    },
    addTriggerToSelectedItemTriggerTimestampCollection(state, payload) {
      if (state.selectedItemTriggerTimestampCollection === null) {
        state.selectedItemTriggerTimestampCollection = new Map();
      }

      if (state.selectedItemTriggerTimestampCollection.has(payload.timestamp)) {
        state.selectedItemTriggerTimestampCollection.set(payload.timestamp, [
          ...state.selectedItemTriggerTimestampCollection.get(
            payload.timestamp
          ),
          payload.startNumber
        ]);
      } else {
        state.selectedItemTriggerTimestampCollection.set(payload.timestamp, [
          payload.startNumber
        ]);
      }
    },
    clearSelectedItemTriggerTimestampCollection(state) {
      if (state.selectedItemTriggerTimestampCollection !== null) {
        state.selectedItemTriggerTimestampCollection.clear();
      }
    },
    setSelectedItemHeatRunCollection(state, heatRunCollection) {
      state.selectedItemHeatRunCollection = heatRunCollection;
    },
    addBannerMessageToSelectedItem(state, bannerMessage) {
      state.selectedItem.bannerMessages.push(bannerMessage);
    },
    removeBannerMessageFromSelectedItem(state, bannerMessage) {
      const bannerMessageIndex = state.selectedItem.bannerMessages.findIndex(
        el => el.id === bannerMessage.id
      );

      if (bannerMessageIndex > -1) {
        Vue.delete(state.selectedItem.bannerMessages, bannerMessageIndex);
      }
    },
    addCompetitorInformation(state, competitorInformation) {
      const heatIndex = state.selectedItem.heats.findIndex(
        el => el.id === competitorInformation.heatId
      );

      state.selectedItem.heats[heatIndex].competitorInformation.push({
        competitorId: competitorInformation.competitorId,
        note: competitorInformation.note,
        excludeFromReferenceRun: competitorInformation.excludeFromReferenceRun
      });
    },
    updateCompetitorInformation(state, competitorInformation) {
      const heatIndex = state.selectedItem.heats.findIndex(
        el => el.id === competitorInformation.heatId
      );

      const competitorInformationIndex = state.selectedItem.heats[
        heatIndex
      ].competitorInformation.findIndex(
        el => el.competitorId === competitorInformation.competitorId
      );

      state.selectedItem.heats[heatIndex].competitorInformation[
        competitorInformationIndex
      ].note = competitorInformation.note;
      state.selectedItem.heats[heatIndex].competitorInformation[
        competitorInformationIndex
      ].excludeFromReferenceRun = competitorInformation.excludeFromReferenceRun;
    }
  },
  actions: {
    fetchItems({ commit }, filter) {
      const query = Object.entries(filter)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");

      return new Promise((resolve, reject) => {
        Repository.get(`${resource}?${query}`)
          .then(response => {
            commit("setItems", response.data.data);
            resolve(response);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    fetchOpenItems({ commit }, filter) {
      const query = Object.entries(filter)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");

      return new Promise((resolve, reject) => {
        Repository.get(`${resource}/open?${query}`)
          .then(response => {
            commit("setOpenItems", response.data.data);
            resolve(response);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    fetchSelectedItem({ commit }, eventId) {
      commit("clearSelectedItem");

      return new Promise((resolve, reject) => {
        Repository.get(`${resource}/${eventId}/detail`)
          .then(response => {
            if (response.data.status === 0) {
              commit("setSelectedItem", response.data.data[0]);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    fetchSelectedOpenItem({ commit }, eventId) {
      return new Promise((resolve, reject) => {
        Repository.get(`${resource}/${eventId}/detail`)
          .then(response => {
            commit("setSelectedOpenItem", response.data.data[0]);
            resolve(response);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    fetchItemsByUser({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.get(
          `/user/${payload.userId}/events?limit=${payload.filter.limit}&offset=${payload.filter.offset}&search=${payload.filter.search}`
        )
          .then(response => {
            commit("setItemsByUser", response.data.data);
            resolve(response);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    fetchSelectedItemByUser({ commit }, eventId) {
      return new Promise((resolve, reject) => {
        Repository.get(`${resource}/${eventId}/detail`)
          .then(response => {
            commit("setSelectedItemByUser", response.data.data[0]);
            resolve(response);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    createEvent({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(`/user/${payload.userId}${resource}`, payload.data)
          .then(response => {
            if (response.data.status === 0) {
              commit("addItemToItemsByUser", response.data.data[0]);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    duplicateItem({ commit }, eventId) {
      return new Promise((resolve, reject) => {
        Repository.post(`${resource}/${eventId}/copy`)
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    deleteItem({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.delete(`user/${payload.userId}/events/${payload.eventId}`)
          .then(response => {
            if (response.data.status === 0) {
              commit("removeItemFromItemsByUser", payload.eventId);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    createCompetitorForItem({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/competitor`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    createCompetitorsForItem({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/competitors`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    updateCompetitorForItem({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/competitors/${payload.competitorId}`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              commit("updateCompetitorOfSelectedItem", response.data.data[0]);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    addCompetitorsToItemHeat({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/heats/${payload.heatId}/competitors`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    restartItem({ commit }, eventId) {
      return new Promise((resolve, reject) => {
        Repository.post(`${resource}/${eventId}/restart`)
          .then(response => {
            if (response.data.status === 0) {
              commit("setSelectedItem", response.data.data[0]);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    cancelRunByCompetitor({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/heats/${payload.heatId}/competitor/${payload.competitorId}/cancel`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    resetCancellationByCompetitor({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/heats/${payload.heatId}/runs/${payload.runId}/resetCancellation`
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    restartRunByCompetitor({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/heats/${payload.heatId}/runs/${payload.runId}/reset?includeStartgroup=${payload.includeStartgroup}`
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    cancelHeat({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/heats/${payload.heatId}/cancel`
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    finishHeat({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/heats/${payload.heatId}/finish`
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    deleteCompetitor({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.delete(
          `${resource}/${payload.eventId}/competitors/${payload.competitorId}`
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    deleteCompetitors({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.delete(`${resource}/${payload.eventId}/competitors/`, {
          data: payload.data
        })
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    setCompetitorStartnumbers({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/competitors/startnumber`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              commit("setCompetitorsOfSelectedItem", response.data.data);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    updateItem({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `/user/${payload.userId}${resource}/${payload.eventId}/${payload.step}`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              commit("updateItemInItemsByUser", response.data.data[0]);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    updateRunTimeByCompetitor({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `/user/${payload.userId}${resource}/${payload.eventId}/heat/${payload.heatId}/competitor/${payload.competitorId}/runtime?resetRunStatus=true`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    updateRunTimesByCompetitor({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `/user/${payload.userId}${resource}/${payload.eventId}/heat/${payload.heatId}/competitor/${payload.competitorId}/runtimes?resetRunStatus=true`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    removeRunTimeByCompetitor({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/heats/${payload.heatId}/runs/${payload.runId}/section/${payload.sectionId}/reset`
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    reassignRun({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/heats/${payload.heatId}/runs/${payload.runId}/reassign`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    armHeat({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/heats/${payload.heatId}/activate`
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    disarmHeat({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/heats/${payload.heatId}/deactivate`
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    createEventDocument({ commit }, payload) {
      const formData = new FormData();
      formData.append(
        "document",
        new Blob([JSON.stringify(payload.dto)], {
          type: "application/json"
        })
      );
      formData.append("file", payload.file);

      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };

      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/document`,
          formData,
          config
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    createHeatDocument({ commit }, payload) {
      const formData = new FormData();
      formData.append(
        "document",
        new Blob([JSON.stringify(payload.dto)], {
          type: "application/json"
        })
      );
      formData.append("file", payload.file);

      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };

      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/heats/${payload.heatId}/document`,
          formData,
          config
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    deleteDocument({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.delete(
          `${resource}/${payload.eventId}/document/${payload.documentId}`
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    updateDocument({ commit }, payload) {
      const formData = new FormData();
      formData.append(
        "document",
        new Blob([JSON.stringify(payload.dto)], {
          type: "application/json"
        })
      );
      if (payload.file !== null) {
        formData.append("file", payload.file);
      }

      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };

      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/document/${payload.documentId}/update`,
          formData,
          config
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    setReferenceRunOfHeat({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/heats/${payload.heatId}/reference`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    setReferenceRunOfEvent({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/reference`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    fetchLogItems({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.get(`${resource}/${payload.eventId}/logs/all`)
          .then(response => {
            commit("setLogItems", response.data.data);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteLogItem({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.delete(`${resource}/${payload.eventId}/log/${payload.logId}`)
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    updateLogEntry({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/log/${payload.logId}`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    buildSelectedItemTriggerTimestampCollection({ state, commit, getters }) {
      commit("clearSelectedItemTriggerTimestampCollection");

      state.selectedItem.heats.map(heat => {
        heat.heatCycle.entries.map(entry => {
          const sectionKeys = Object.keys(entry.data.sections);

          sectionKeys.map(key => {
            const competitor = getters.getCompetitorById(entry.competitorId);
            commit("addTriggerToSelectedItemTriggerTimestampCollection", {
              timestamp: entry.data.sections[key].timestamp_100ns,
              startNumber: competitor ? competitor.startNumber : null
            });
          });
        });
      });
    },
    buildSelectedItemHeatRunCollection({ state, commit }) {
      const heatRunCollection = new Map();

      state.selectedItem.heats.map(heat => {
        heat.heatCycle.entries.map(entry => {
          heatRunCollection.set(entry.runId, heat.id);
        });
      });

      commit("setSelectedItemHeatRunCollection", heatRunCollection);
    },
    updateEventSettingsTriggerFilterTime({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/settings/triggerFilterTime`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    updateEventSettingsRankBestOfCount({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/settings/ranking`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    updateEventSettingsMassStart({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/settings/massStart`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    fetchSelectedItemTrigger({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.get(`${resource}/${payload.eventId}/trigger`)
          .then(response => {
            commit("setSelectedItemTrigger", response.data.data);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    createSelectedItemBannerMessage({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(`${resource}/${payload.eventId}/banner`, payload.data)
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    deleteSelectedItemBannerMessage({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.delete(
          `${resource}/${payload.eventId}/banner/${payload.bannerId}`
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    createTimePostingEntry({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/heats/${payload.heatId}/runs/${payload.runId}/timePostings`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    deleteTimePostingEntry({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.delete(
          `${resource}/${payload.eventId}/heats/${payload.heatId}/runs/${payload.runId}/timePostings/${payload.timePostingId}`
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    sendCompetitorsEmail({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(`${resource}/${payload.eventId}/competitors/all/inform`)
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    setHeatRunTimes({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `/user/${payload.userId}${resource}/${payload.eventId}/heat/${payload.heatId}/runtimes?resetRunStatus=true`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    updateCompetitorNote({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/heats/${payload.heatId}/competitor/${payload.competitorId}/note`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    updateExcludeFromReferenceRun({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/heats/${payload.heatId}/competitor/${payload.competitorId}/excludeFromReferenceRun`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    setStartCompetitor({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.eventId}/heats/${payload.heatId}/startlist/next/${payload.competitorId}`
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    }
  }
};
