import Repository from "../api/repository";

export default {
  namespaced: true,
  state: {
    items: []
  },
  getters: {
    getItemsByDeviceId: state => id => {
      return state.items
        .sort((a, b) => a.timestamp - b.timestamp)
        .filter(item => item.deviceId === id);
    },
    getItemsByDeviceIds: state => ids => {
      return state.items
        .sort((a, b) => a.timestamp - b.timestamp)
        .filter(item => ids.includes(item.deviceId));
    }
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    addItem(state, item) {
      state.items.unshift(item);
    },
    clearItems(state) {
      state.items = [];
    },
    clearItemsByDeviceId(state, id) {
      state.items = state.items.filter(item => item.deviceId !== id);
    }
  }
};
