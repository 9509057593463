export default {
  bg: {
    imageUrl: "style/images/flags/bg.svg"
  },
  bs: {
    imageUrl: "style/images/flags/bs.svg"
  },
  cs: {
    imageUrl: "style/images/flags/rs.svg"
  },
  da: {
    imageUrl: "style/images/flags/dk.svg"
  },
  de: {
    imageUrl: "style/images/flags/de.svg"
  },
  en: {
    imageUrl: "style/images/flags/gb.svg"
  },
  es: {
    imageUrl: "style/images/flags/es.svg"
  },
  fi: {
    imageUrl: "style/images/flags/fi.svg"
  },
  fr: {
    imageUrl: "style/images/flags/fr.svg"
  },
  gr: {
    imageUrl: "style/images/flags/gr.svg"
  },
  hr: {
    imageUrl: "style/images/flags/hr.svg"
  },
  hu: {
    imageUrl: "style/images/flags/hu.svg"
  },
  it: {
    imageUrl: "style/images/flags/it.svg"
  },
  lb: {
    imageUrl: "style/images/flags/lb.svg"
  },
  lv: {
    imageUrl: "style/images/flags/lv.svg"
  },
  mk: {
    imageUrl: "style/images/flags/mk.svg"
  },
  mt: {
    imageUrl: "style/images/flags/mt.svg"
  },
  nl: {
    imageUrl: "style/images/flags/nl.svg"
  },
  no: {
    imageUrl: "style/images/flags/no.svg"
  },
  pl: {
    imageUrl: "style/images/flags/pl.svg"
  },
  pt: {
    imageUrl: "style/images/flags/pt.svg"
  },
  ro: {
    imageUrl: "style/images/flags/ro.svg"
  },
  se: {
    imageUrl: "style/images/flags/se.svg"
  },
  sk: {
    imageUrl: "style/images/flags/sk.svg"
  },
  sq: {
    imageUrl: "style/images/flags/al.svg"
  },
  sv: {
    imageUrl: "style/images/flags/sv.svg"
  },
  tr: {
    imageUrl: "style/images/flags/tr.svg"
  }
};
