<template>
  <div class="stickyNavigation">
    <v-toolbar class="nav alge--bg">
      <v-btn
        v-if="$vuetify.breakpoint.mdAndDown"
        icon
        @click="showSideNavigation = !showSideNavigation"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <v-btn :to="{ name: 'Home' }" exact text class="px-2">
        ALGE-RESULTS
      </v-btn>
      <v-chip v-if="isDevSystem" color="error" small class="ml-2">
        DEV
      </v-chip>

      <v-spacer></v-spacer>

      <TheLanguageSwitcher />

      <v-tooltip left :open-delay="300">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" :to="{ name: 'Dashboard' }" exact icon>
            <v-icon>{{ panelIcon }}</v-icon>
          </v-btn>
        </template>
        {{ tooltipText }}
      </v-tooltip>
    </v-toolbar>

    <v-expand-transition>
      <TheSideNavigation
        v-if="isLoggedIn"
        v-show="$vuetify.breakpoint.mdAndDown && showSideNavigation"
        embedded
      />
    </v-expand-transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TheLanguageSwitcher from "@/components/TheLanguageSwitcher";
import TheSideNavigation from "@/components/TheSideNavigation";

export default {
  name: "TheNavigation",
  components: {
    TheLanguageSwitcher,
    TheSideNavigation
  },
  data() {
    return {
      showSideNavigation: false
    };
  },
  computed: {
    ...mapGetters({
      isDevSystem: "settings/isDevSystem",
      isLoggedIn: "user/isLoggedIn"
    }),
    panelIcon() {
      return this.isLoggedIn ? "mdi-account-cog" : "mdi-login";
    },
    tooltipText() {
      return this.isLoggedIn
        ? this.$i18n.t("hints.dashboard")
        : this.$i18n.t("hints.login");
    }
  },
  watch: {
    $route(to, from) {
      this.showSideNavigation = false;
    }
  }
};
</script>

<style>
.alge--text {
  color: #ffed00 !important;
}
.alge--bg {
  background-color: #ffed00 !important;
}
.nav .v-btn--active::before {
  opacity: 0 !important;
}
.stickyNavigation {
  position: sticky;
  top: 0;
  z-index: 99;
}
</style>
