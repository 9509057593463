import Vue from "vue";
import VueRouter from "vue-router";

import i18n from "../plugins/i18n";
import vuetify from "../plugins/vuetify";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue")
  },
  {
    path: "/event/:id",
    component: () =>
      import(
        /* webpackChunkName: "EventDetails" */ "../views/EventDetails.vue"
      ),
    props: true,
    children: [
      {
        path: "",
        name: "Overview",
        component: () =>
          import(
            /* webpackChunkName: "TabOverview" */ "../components/TabOverview.vue"
          )
      },
      {
        path: "heats",
        name: "Heats",
        component: () =>
          import(
            /* webpackChunkName: "TabHeats" */ "../components/TabHeats.vue"
          )
      },
      {
        path: "competitors",
        name: "Competitors",
        component: () =>
          import(
            /* webpackChunkName: "TabCompetitors" */ "../components/TabCompetitors.vue"
          )
      },
      {
        path: "live",
        name: "Live",
        component: () =>
          import(/* webpackChunkName: "TabLive" */ "../components/TabLive.vue")
      }
    ]
  },
  {
    path: "/registration/:id",
    name: "Registration",
    component: () =>
      import(
        /* webpackChunkName: "EventRegistration" */ "../views/EventRegistration.vue"
      ),
    props: true
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Login.vue")
  },
  {
    path: "/signup",
    name: "SignUp",
    component: () =>
      import(/* webpackChunkName: "SignUp" */ "../views/SignUp.vue")
  },
  {
    path: "/password-reset",
    name: "PasswordReset",
    component: () =>
      import(
        /* webpackChunkName: "PasswordReset" */ "../views/PasswordReset.vue"
      )
  },
  {
    path: "/panel",
    component: () =>
      import(/* webpackChunkName: "Panel" */ "../views/Panel.vue"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "PanelDashboard" */ "../components/PanelDashboard.vue"
          )
      },
      {
        path: "events",
        name: "Events",
        component: () =>
          import(
            /* webpackChunkName: "PanelEvents" */ "../components/PanelEvents.vue"
          )
      },
      {
        path: "events/create",
        name: "EventsCreate",
        component: () =>
          import(
            /* webpackChunkName: "PanelEventsCreate" */ "../components/PanelEventsCreate.vue"
          )
      },
      {
        path: "events/edit/:id",
        name: "EventsEdit",
        component: () =>
          import(
            /* webpackChunkName: "PanelEventsEdit" */ "../components/PanelEventsEdit.vue"
          ),
        props: true
      },
      {
        path: "devices",
        name: "Devices",
        component: () =>
          import(
            /* webpackChunkName: "PanelDevices" */ "../components/PanelDevices.vue"
          )
      },
      {
        path: "devices/edit/:id",
        name: "DevicesEdit",
        component: () =>
          import(
            /* webpackChunkName: "PanelDevicesEdit" */ "../components/PanelDevicesEdit.vue"
          ),
        props: true
      },
      {
        path: "users",
        name: "Users",
        component: () =>
          import(
            /* webpackChunkName: "PanelUsers" */ "../components/PanelUsers.vue"
          )
      },
      {
        path: "maintenance",
        name: "Maintenance",
        component: () =>
          import(
            /* webpackChunkName: "PanelMaintenanceMail" */ "../components/PanelMaintenanceMail.vue"
          )
      },
      {
        path: "info",
        name: "Info",
        component: () =>
          import(
            /* webpackChunkName: "PanelInfoMail" */ "../components/PanelInfoMail.vue"
          )
      },
      {
        path: "account",
        component: () =>
          import(
            /* webpackChunkName: "PanelAccount" */ "../components/PanelAccount.vue"
          ),
        children: [
          {
            path: "",
            name: "Account",
            component: () =>
              import(
                /* webpackChunkName: "PanelAccountTabAccount" */ "../components/PanelAccountTabAccount.vue"
              )
          },
          {
            path: "security",
            name: "Security",
            component: () =>
              import(
                /* webpackChunkName: "PanelAccountTabSecurity" */ "../components/PanelAccountTabSecurity.vue"
              )
          }
        ]
      },
      {
        path: "my-events",
        name: "UserEvents",
        component: () =>
          import(
            /* webpackChunkName: "PanelUserEvents" */ "../components/PanelUserEvents.vue"
          )
      },
      {
        path: "history",
        name: "History",
        component: () =>
          import(
            /* webpackChunkName: "PanelHistory" */ "../components/PanelHistory.vue"
          )
      },
      {
        path: "salesperson",
        name: "SalesPerson",
        component: () =>
          import(
            /* webpackChunkName: "PanelSalesPerson" */ "../components/PanelSalesPerson.vue"
          )
      }
    ]
  },
  {
    path: "/delete",
    name: "DeleteConfirmation",
    component: () =>
      import(
        /* webpackChunkName: "DeleteConfirmation" */ "../views/DeleteConfirmation.vue"
      )
  },
  {
    path: "/changes",
    name: "Changes",
    component: () =>
      import(/* webpackChunkName: "Changes" */ "../views/Changes.vue")
  },
  {
    path: "/status",
    name: "Status",
    component: () =>
      import(/* webpackChunkName: "Status" */ "../views/Status.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/limit",
    name: "LimitReached",
    component: () =>
      import(/* webpackChunkName: "LimitReached" */ "../views/LimitReached.vue")
  },
  {
    path: "/service",
    name: "NotAvailable",
    component: () =>
      import(/* webpackChunkName: "NotAvailable" */ "../views/NotAvailable.vue")
  },
  {
    path: "*",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "../views/NotFound.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach((to, from, next) => {
  let preferredLanguage = localStorage.settings
    ? JSON.parse(localStorage.settings).locale
    : null;
  let browserLanguage = navigator.language.substring(0, 2);
  let language = preferredLanguage || browserLanguage;

  if (language && i18n.availableLocales.includes(language)) {
    i18n.locale = language;
    vuetify.framework.lang.current = language;
    document.querySelector("html").setAttribute("lang", language);
  } else {
    i18n.locale = i18n.fallbackLocale;
    vuetify.framework.lang.current = i18n.fallbackLocale;
    document.querySelector("html").setAttribute("lang", i18n.fallbackLocale);
  }
  next();
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.user === undefined) {
      next({
        name: "Login",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.onError(error => {
  console.error(error);

  if (error.name === "ChunkLoadError") {
    setTimeout(function() {
      window.location.reload();
      console.error(error);
    }, 2000);
  }
});

export default router;
