/* eslint-disable */

import router from "@/router";
import i18n from "@/plugins/i18n";
import Repository from "../api/repository";

const resource = "/user";

export default {
  namespaced: true,
  state: {
    user: null,
    token: null,
    events: [],
    userEvents: []
  },
  getters: {
    isLoggedIn: state => {
      if (state.user) return true;

      return false;
    },
    hasTimingPointsAccount: state => {
      if (state.user && state.user.roles) {
        return state.user.roles.includes("TIMING_POINT_ACCOUNT");
      }

      return false;
    },
    isAdmin: state => {
      if (state.user && state.user.roles) {
        return state.user.roles.includes("ADMIN");
      }

      return false;
    },
    usesOAuth: state => {
      if (!state.user) return undefined;

      switch (state.user.primaryAuthentication) {
        case "G_OAUTH":
          return true;
        case "PASSWORD":
        default:
          return false;
      }
    }
  },
  mutations: {
    setUser(state, user) {
      localStorage.setItem("user", JSON.stringify(user));
      state.user = user;
    },
    unsetUser(state) {
      localStorage.removeItem("user");
      state.user = null;
    },
    setToken(state, token) {
      localStorage.setItem("token", JSON.stringify(token));
      state.token = token;
    },
    unsetToken(state) {
      localStorage.removeItem("token");
      state.token = null;
    },
    setEvents(state, events) {
      state.events = events;
    },
    setUserEvents(state, events) {
      state.userEvents = events;
    }
  },
  actions: {
    loadUser({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        if (localStorage.user !== undefined) {
          const token = JSON.parse(localStorage.token);
          const user = JSON.parse(localStorage.user);

          commit("setToken", token);
          commit("setUser", user);
          resolve(user);
        } else {
          reject();
        }
      });
    },
    fetchUser({ commit }) {
      return new Promise((resolve, reject) => {
        Repository.get(`${resource}/current`)
          .then(response => {
            if (response.data.status === 0) {
              const user = response.data.data[0];

              commit("setUser", user);
              resolve(user);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    createUser({ commit }, data) {
      return new Promise((resolve, reject) => {
        Repository.post(`${resource}/register`, data)
          .then(response => {
            if (response.data.status === 0) {
              commit("setToken", response.headers.authorization);
              commit("setUser", response.data.data[0]);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    updateUser({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `/users/${state.user.id}/update?createTpAccount=${data.createTimingPointAccount}`,
          data
        )
          .then(response => {
            if (response.data.status === 0) {
              commit("setToken", response.headers.authorization);
              commit("setUser", response.data.data[0]);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    updatePassword({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        Repository.post(`/users/${state.user.id}/password/update`, data)
          .then(response => {
            if (response.data.status === 0) {
              const user = response.data.data[0];

              commit("setUser", user);
              resolve(user);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    authenticateUser({ commit, dispatch }, credentials) {
      return new Promise((resolve, reject) => {
        Repository.post(`${resource}/login`, credentials, {
          withCredentials: true
        })
          .then(response => {
            if (response.data.status === 0) {
              const token = response.headers.authorization;
              const user = response.data.data[0];
              commit("setToken", token);
              commit("setUser", user);
              dispatch("devices/fetchItems", user.id, {
                root: true
              }).catch(() => {});
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    logoutUser({ commit }) {
      return new Promise((resolve, reject) => {
        Repository.post(`${resource}/logout`)
          .then(response => {
            if (response.data.status === 0) {
              google.accounts.id.disableAutoSelect();
              commit("unsetToken");
              commit("unsetUser");
              commit("devices/unsetItems", null, { root: true });
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    reauthenticateUser({ commit }) {
      google.accounts.id.disableAutoSelect();
      commit("unsetToken");
      commit("unsetUser");
      commit("devices/unsetItems", null, { root: true });
      if (router.currentRoute.name !== "Login") {
        router.push({
          name: "Login",
          query: { redirect: router.currentRoute.fullPath }
        });
      }
    },
    registerUserToEvent({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.userId}/event/${payload.eventId}/register`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    fetchEvents({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.get(
          `${resource}/${payload.userId}/events?limit=${payload.filter.limit}&offset=${payload.filter.offset}&search=${payload.filter.search}`
        )
          .then(response => {
            commit("setEvents", response.data.data);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    fetchUserEvents({ commit }, userId) {
      return new Promise((resolve, reject) => {
        Repository.get(`${resource}/${userId}/events/registered?limit=500`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    depositTP({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `/users/${payload.userId}/timingpoints/deposit`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    sendMaintenanceInfo({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(`/mail/maintenance`, payload.data)
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    sendMailInfo({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(`/mail/inform`, payload.data)
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    sendDeleteAccount({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(`/users/${payload.userId}/delete`)
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteAccount({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(`/users/delete/submit`, payload.data)
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    upgradeAccount({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(`/users/${payload.userId}/timingpoints/create`)
          .then(response => {
            if (response.data.status === 0) {
              const user = response.data.data[0];

              commit("setUser", user);
              resolve(user);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};
