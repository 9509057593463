<template>
  <div class="d-flex justify-space-between">
    <v-btn
      :to="{ name: 'History' }"
      class="flex-grow-1 py-6 text-none"
      tile
      text
      exact
    >
      {{ timingPoints }} TPs
    </v-btn>
    <v-btn
      :to="{ name: 'SalesPerson' }"
      class="py-6"
      color="success"
      tile
      exact
    >
      <v-icon>mdi-shopping</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "TheTimingPoints",
  data() {
    return {
      loading: true
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      timingPoints: state => state.timingpoints.balance
    })
  },
  methods: {
    ...mapActions({
      fetchBalance: "timingpoints/fetchBalance"
    })
  },
  created() {
    this.fetchBalance(this.user.id)
      .then(() => (this.loading = false))
      .catch(() => (this.loading = false));
  }
};
</script>
