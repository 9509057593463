import Vue from "vue";
import Vuetify from "vuetify/lib";
import en from "vuetify/es5/locale/en";
import de from "vuetify/es5/locale/de";
import no from "vuetify/es5/locale/no";
import ro from "vuetify/es5/locale/ro";
import es from "vuetify/es5/locale/es";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        accent: "#1976D2"
      }
    }
  },
  icons: {
    iconfont: "mdi"
  },
  lang: {
    locales: { en, de, no, ro, es }
  }
});
