/* eslint-disable */

import Repository from "../api/repository";

const resource = "/users";

export default {
  namespaced: true,
  state: {
    balance: 0,
    history: []
  },
  mutations: {
    setBalance(state, balance) {
      state.balance = balance;
    },
    setHistory(state, history) {
      state.history = history;
    },
    addHistoryEntry(state, entry) {
      state.history.unshift(entry);
      state.history.pop();
    },
    updatehistoryEntry(state, entry) {
      const index = state.history.findIndex(e => e.date === entry.date);
      
      if (index > -1) {
        Vue.set(state.history, index, entry);
      }
    },
  },
  actions: {
    fetchBalance({ commit }, userId) {
      return new Promise((resolve, reject) => {
        Repository.get(`${resource}/${userId}/timingpoints`)
          .then(response => {
            if (response.data.status === 0) {
            commit("setBalance", response.data.data[0]);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    fetchHistory({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.get(`${resource}/${payload.userId}/timingpoints/history?limit=${payload.filter.limit}&offset=${payload.filter.offset}`)
          .then(response => {
            if (response.data.status === 0) {
              commit("setHistory", response.data.data);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    }
  }
};
