/* eslint-disable */

import axios from "axios";
import store from "@/store";
import router from "@/router";

const apiURL = process.env.VUE_APP_API_URL;
const api = axios.create({
  baseURL: apiURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

api.interceptors.request.use(function(config) {
  if (store.state.user.token) {
    config.headers["Authorization"] = store.state.user.token;
  }
  return config;
});

api.interceptors.response.use(
  function(response) {
    switch (response.data.status) {
      case -1006:
        store.dispatch("user/reauthenticateUser");
        return Promise.reject(response);
      case -9000:
        router.push({ name: "LimitReached" });
        break;
    }

    return response;
  },
  function(error) {
    if (!error.response) return Promise.reject(error);

    switch (error.response.status) {
      case 403:
        store.dispatch("user/reauthenticateUser");
        break;
      case 503:
        router.push({ name: "NotAvailable" });
        break;
      default:
        return Promise.reject(error);
    }
  }
);

export default api;
