import Vue from "vue";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";

export default {
  namespaced: true,
  state: {
    client: null,
    reconnecting: false,
    subscriptions: [],
    reconnectTimeoutID: null,
    forceTimeoutID: null
  },
  getters: {
    isCreated: state => {
      return state.client;
    },
    isConnected: state => {
      return state.client ? state.client.connected : false;
    },
    isReconnecting: state => {
      return state.reconnecting;
    }
  },
  mutations: {
    setClient(state, client) {
      state.client = client;
    },
    unsetClient(state) {
      state.client = null;
    },
    updateReconnecting(state, value) {
      state.reconnecting = value;
    },
    addSubscription(state, payload) {
      state.subscriptions.push({
        subscription: payload.subscription,
        topic: payload.topic
      });
    },
    removeSubscription(state, id) {
      const index = state.subscriptions.findIndex(
        sub => sub.subscription.id === id
      );

      if (index >= 0) {
        Vue.delete(state.subscriptions, index);
      }
    },
    setReconnectTimeout(state, id) {
      state.reconnectTimeoutID = id;
    },
    clearReconnectTimeout(state) {
      if (state.reconnectTimeoutID !== null) {
        clearTimeout(state.reconnectTimeoutID);
        state.reconnectTimeoutID = null;
      }
    },
    setForceTimeout(state, id) {
      state.forceTimeoutID = id;
    },
    clearForceTimeout(state) {
      if (state.forceTimeoutID !== null) {
        clearTimeout(state.forceTimeoutID);
        state.forceTimeoutID = null;
      }
    }
  },
  actions: {
    connect({ commit, dispatch, state }) {
      commit("clearForceTimeout");
      const ws = new SockJS(`${process.env.VUE_APP_BASE_URL}/server`);
      const client = Stomp.over(ws, {
        heartbeat: {
          incoming: 10000,
          outgoing: 10000
        },
        debug: false
      });

      const forceTimeoutID = setTimeout(() => {
        dispatch("disconnect");
        dispatch("connect");
      }, 30000);
      commit("setForceTimeout", forceTimeoutID);

      client.connect(
        {},
        frame => {
          commit("clearForceTimeout");
          commit("setClient", client);
          commit("updateReconnecting", false);
        },
        error => {
          commit("clearForceTimeout");
          dispatch("disconnect");
          commit("updateReconnecting", true);
          if (state.reconnectTimeoutID === null) {
            const reconnectTimeoutID = setTimeout(() => {
              commit("clearReconnectTimeout");
              dispatch("connect");
            }, 3000);
            commit("setReconnectTimeout", reconnectTimeoutID);
          }
        }
      );
    },
    disconnect({ commit, state, getters }) {
      if (getters.isConnected) {
        state.client.disconnect();
      }
      commit("unsetClient");
    },
    subscribe({ commit, state, getters }, payload) {
      if (getters.isConnected) {
        const subscription = state.client.subscribe(
          payload.topic,
          payload.callback
        );
        commit("addSubscription", {
          subscription,
          topic: payload.topic
        });

        return subscription.id;
      }

      return null;
    },
    unsubscribe({ commit, state }, subscriptionId) {
      if (state.client) {
        const index = state.subscriptions.findIndex(
          sub => sub.subscription.id === subscriptionId
        );

        if (index >= 0) {
          const header = {
            destination: state.subscriptions[index].topic
          };

          state.subscriptions[index].subscription.unsubscribe(header);
          commit("removeSubscription", subscriptionId);
        }
      }
    }
  }
};
