/* eslint-disable */

import Repository from "../api/repository";

const resource = "/time";

export default {
  namespaced: true,
  state: {
    serverTime: null,
    localTimeStamp: null
  },
  mutations: {
    setServerTime(state, serverTime) {
      state.serverTime = serverTime;
    },
    setLocalTimeStamp(state) {
      state.localTimeStamp = Date.now();
    }
  },
  actions: {
    fetchTime({ commit }) {
      return new Promise((resolve, reject) => {
        Repository.get(`${resource}/now`)
          .then(response => {
            commit("setServerTime", response.data.data[0]);
            commit("setLocalTimeStamp");
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};
