/* eslint-disable */

import Vue from "vue";
import Repository from "../api/repository";

const resource = "/devices";

export default {
  namespaced: true,
  state: {
    items: null
  },
  getters: {
    getItemById: state => id => {
      if (state.items) {
        const item = state.items.find(item => item.id === id);
        return item === undefined ? null : item;
      }

      return null;
    }
  },
  mutations: {
    setItems(state, devices) {
      state.items = devices;
    },
    unsetItems(state) {
      state.items = null;
    },
    addItemToItems(state, device) {
      if (state.items && !state.items.some(el => el.id === device.id)) {
        state.items.push(device);
      }
    },
    updateItemInItems(state, device) {
      if (state.items) {
        const index = state.items.findIndex(item => item.id === device.id);
        if (index !== -1) {
          Vue.set(state.items, index, device);
        }
      }
    },
    removeItemFromItems(state, device) {
      if (state.items) {
        const index = state.items.findIndex(item => item.id === device.id);
        if (index !== -1) {
          state.items.splice(index, 1);
        }
      }
    }
  },
  actions: {
    fetchItems({ commit }, userId) {
      return new Promise((resolve, reject) => {
        Repository.get(`/user/${userId}/devices`)
          .then(response => {
            commit("setItems", response.data.data);
            resolve(response.data.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    sendSerialNumber({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(`${resource}/${payload.id}/register`, payload.data)
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    sendPin({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(`${resource}/${payload.id}/authorize`, payload.data)
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    deregisterItem({ commit }, deviceId) {
      return new Promise((resolve, reject) => {
        Repository.post(`${resource}/${deviceId}/deregister`)
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    updateItemName({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(`${resource}/${payload.deviceId}/name`, payload.data)
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    updateItemTimezone({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.deviceId}/timeoffset`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    updateItemSyncConfig({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(`${resource}/${payload.deviceId}/sync`, payload.data)
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    updateItemChannelTimeDelay({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.deviceId}/deadtime`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    updateItemChannelPulseHold({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.deviceId}/pulsehold`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    setItemChannelStatus({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(
          `${resource}/${payload.deviceId}/channels/all/state`,
          payload.data
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    fetchItemStatus({ commit }, deviceId) {
      return new Promise((resolve, reject) => {
        Repository.post(`${resource}/${deviceId}/systemstatus/fetch`)
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    fetchTriggersByDeviceChannel({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.get(
          `devices/${payload.deviceId}/channel/${payload.channelId}/trigger?limit=${payload.query.limit}&offset=${payload.query.offset}`
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    activateItem({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.post(`${resource}/${payload.deviceId}/activate`)
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    fetchDeviceSIMStatus({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.get(`devices/${payload.deviceId}/activated`)
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    deleteTriggersByChannel({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Repository.delete(
          `${resource}/${payload.deviceId}/channel/${payload.channelId}/trigger`
        )
          .then(response => {
            if (response.data.status === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    }
  }
};
