<template>
  <v-app>
    <TheNavigation />
    <div class="stickyMessages">
      <TheConnectionStatus />
      <TheDeviceWarnings v-if="isLoggedIn" />
    </div>
    <v-main>
      <router-view />
    </v-main>
    <TheFooter />
  </v-app>
</template>

<script>
/* eslint-disable */

import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import TheNavigation from "./components/TheNavigation";
import TheConnectionStatus from "./components/TheConnectionStatus";
import TheDeviceWarnings from "./components/TheDeviceWarnings";
import TheFooter from "./components/TheFooter";

export default {
  name: "App",
  components: {
    TheNavigation,
    TheConnectionStatus,
    TheDeviceWarnings,
    TheFooter
  },
  data() {
    return {
      triggerSubscription: null,
      deviceSubscription: null,
      balanceSubscription: null,
      queueSubscription: null
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn",
      isCreated: "socket/isCreated",
      isConnected: "socket/isConnected"
    })
  },
  watch: {
    isConnected(value) {
      if (value) {
        this.initQueueSubscription();
        if (this.isLoggedIn) {
          this.initUserSubscriptions();
        }
      }
    },
    isLoggedIn(value) {
      if (value) {
        if (this.isConnected) {
          this.initUserSubscriptions();
        }
      } else {
        this.resetUserSubscriptions();
      }
    }
  },
  methods: {
    ...mapActions({
      loadSettings: "settings/loadSettings",
      fetchTime: "time/fetchTime",
      updateLocalTimeStamp: "time/updateLocalTimeStamp",
      fetchNations: "nations/fetchItems",
      loadUser: "user/loadUser",
      fetchUser: "user/fetchUser",
      fetchDevices: "devices/fetchItems",
      connect: "socket/connect",
      disconnect: "socket/disconnect",
      subscribe: "socket/subscribe",
      unsubscribe: "socket/unsubscribe",
      buildSelectedItemTriggerTimestampCollection:
        "events/buildSelectedItemTriggerTimestampCollection"
    }),
    ...mapMutations({
      setTime: "time/setTime",
      addTrigger: "trigger/addItem",
      addDevice: "devices/addItemToItems",
      updateDevice: "devices/updateItemInItems",
      removeDevice: "devices/removeItemFromItems",
      clearTrigger: "trigger/clearItems",
      updateBalance: "timingpoints/setBalance",
      setSelectedItem: "events/setSelectedItem"
    }),
    initUserSubscriptions() {
      this.subscribe({
        topic: `/topic/user/${this.user.id}/devices/trigger`,
        callback: tick => {
          this.addTrigger(JSON.parse(tick.body).dto);
        }
      }).then(id => (this.triggerSubscription = id));

      this.subscribe({
        topic: `/topic/user/${this.user.id}/devices/status`,
        callback: tick => {
          this.handleDeviceSubscription(JSON.parse(tick.body));
        }
      }).then(id => (this.deviceSubscription = id));

      this.subscribe({
        topic: `/topic/user/${this.user.id}/timingpoints/balance`,
        callback: tick => {
          this.updateBalance(JSON.parse(tick.body).dto.balance);
        }
      }).then(id => (this.balanceSubscription = id));
    },
    initQueueSubscription() {
      this.subscribe({
        topic: `/user/queue/reply`,
        callback: tick => {
          this.handleQueueSubscription(JSON.parse(tick.body));
        }
      }).then(id => (this.queueSubscription = id));
    },
    handleDeviceSubscription(response) {
      switch (response.type) {
        case "ENTITY_ADD":
          this.addDevice(response.dto);
          break;
        case "ENTITY_UPDATE":
          this.updateDevice(response.dto);
          break;
        case "ENTITY_DELETE":
          this.removeDevice(response.dto);
          break;
      }
    },
    handleQueueSubscription(response) {
      switch (response.entityType) {
        case "SportEventDetailDto":
          if (response.type === "ENTITY_UPDATE") {
            this.setSelectedItem(response.dto);
          }

          if (this.user && this.user.id === response.dto.ownerId) {
            this.buildSelectedItemTriggerTimestampCollection();
          }
          break;
      }
    },
    resetUserSubscriptions() {
      this.unsubscribe(this.triggerSubscription);
      this.unsubscribe(this.deviceSubscription);
      this.unsubscribe(this.balanceSubscription);
      this.clearTrigger();
    }
  },
  created() {
    this.connect();
    this.initQueueSubscription();
    this.loadSettings();
    this.fetchTime();
    this.fetchNations();
    this.loadUser()
      .then(() => this.fetchUser())
      .then(user => this.fetchDevices(user.id))
      .catch(() => {});
  },
  beforeDestroy() {
    this.disconnect();
  }
};
</script>
<style scoped>
.stickyMessages {
  position: sticky;
  z-index: 88;
  top: 56px;
}

@media (min-width: 961px) {
  .stickyMessages {
    top: 64px;
  }
}
</style>
