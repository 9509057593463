<template>
  <div :class="[embedded ? 'sideNavigationContainer__mobile' : '']">
    <v-card
      v-if="hasTimingPointsAccount"
      :class="embedded ? '' : 'mb-4'"
      tile
      :flat="embedded"
    >
      <TheTimingPoints />
    </v-card>

    <v-card :flat="embedded">
      <v-list class="pa-0">
        <v-divider v-if="embedded"></v-divider>
        <v-list-item :to="{ name: 'Dashboard' }">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("panel.navigation.dashboard")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-subheader v-if="!embedded && hasTimingPointsAccount">{{
          $t("panel.navigation.settings")
        }}</v-subheader>
        <v-divider v-if="embedded"></v-divider>
        <v-list-item-group v-if="hasTimingPointsAccount">
          <v-list-item :to="{ name: 'Events' }">
            <v-list-item-icon>
              <v-icon>mdi-run</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("panel.navigation.events")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :to="{ name: 'Devices' }">
            <v-list-item-icon>
              <v-icon>mdi-devices</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("panel.navigation.devices")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-subheader v-if="!embedded && hasTimingPointsAccount && isAdmin">{{
          $t("panel.navigation.admin")
        }}</v-subheader>
        <v-divider v-if="embedded"></v-divider>
        <v-list-item-group v-if="hasTimingPointsAccount && isAdmin">
          <v-list-item :to="{ name: 'Users' }">
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("panel.navigation.users")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :to="{ name: 'Maintenance' }">
            <v-list-item-icon>
              <v-icon>mdi-email</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("panel.navigation.maintenance")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :to="{ name: 'Info' }">
            <v-list-item-icon>
              <v-icon>mdi-email</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("panel.navigation.info")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-subheader v-if="!embedded">{{
          $t("panel.navigation.user")
        }}</v-subheader>
        <v-divider v-if="embedded"></v-divider>
        <v-list-item-group>
          <v-list-item :to="{ name: 'Account' }">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("panel.navigation.account")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :to="{ name: 'UserEvents' }">
            <v-list-item-icon>
              <v-icon>mdi-run</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("panel.navigation.myEvents")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon class="error--text">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="error--text">{{
                $t("panel.navigation.logout")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import TheTimingPoints from "@/components/TheTimingPoints";

export default {
  name: "TheSideNavigation",
  components: {
    TheTimingPoints
  },
  props: {
    embedded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    ...mapGetters({
      hasTimingPointsAccount: "user/hasTimingPointsAccount",
      isAdmin: "user/isAdmin"
    })
  },
  methods: {
    ...mapActions({
      logoutUser: "user/logoutUser"
    }),
    logout() {
      this.$router.push({ name: "Home" });
      this.logoutUser();
    }
  }
};
</script>
<style scoped>
.sideNavigationContainer__mobile {
  position: absolute;
  width: 100%;
  max-height: calc(100vh - 56px);
  overflow-y: auto;
  z-index: 100;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
</style>
