import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "../locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
  dateTimeFormats: {
    en: {
      time: {
        hour: "2-digit",
        minute: "2-digit"
      },
      shortDate: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      },
      longDate: {
        year: "numeric",
        month: "short",
        day: "numeric"
      },
      longDateWithoutYear: {
        month: "short",
        day: "numeric"
      },
      shortDateTime: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit"
      },
      longDateTime: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      }
    },
    de: {
      time: {
        hour: "2-digit",
        minute: "2-digit"
      },
      shortDate: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      },
      longDate: {
        year: "numeric",
        month: "short",
        day: "numeric"
      },
      longDateWithoutYear: {
        month: "short",
        day: "numeric"
      },
      shortDateTime: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit"
      },
      longDateTime: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      }
    },
    no: {
      time: {
        hour: "2-digit",
        minute: "2-digit"
      },
      shortDate: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      },
      longDate: {
        year: "numeric",
        month: "long",
        day: "numeric"
      },
      shortDateTime: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit"
      },
      longDateTime: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      }
    },
    ro: {
      time: {
        hour: "2-digit",
        minute: "2-digit"
      },
      shortDate: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      },
      longDate: {
        year: "numeric",
        month: "long",
        day: "numeric"
      },
      shortDateTime: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit"
      },
      longDateTime: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      }
    }
  }
});
