import Vue from "vue";

export default {
  namespaced: true,
  state: {
    locale: null,
    time: "DEVICE",
    status: {
      autoRefresh: false
    },
    fieldPreferences: JSON.parse(localStorage.getItem("fieldPreferences")),
    columnPreferences: JSON.parse(localStorage.getItem("columnPreferences")),
    selectionPreferences: JSON.parse(
      localStorage.getItem("selectionPreferences")
    )
  },
  getters: {
    isDevSystem: state => {
      return (
        location.hostname === "localhost" || location.host.includes("dev.")
      );
    },
    getFieldPreferenceById: state => id => {
      if (
        state.fieldPreferences !== null &&
        state.fieldPreferences.hasOwnProperty(id)
      ) {
        return state.fieldPreferences[id];
      }

      return true;
    },
    hasColumnPreferences: state => {
      return state.columnPreferences !== null;
    },
    getColumnPreferenceByIndex: state => index => {
      if (
        state.columnPreferences !== null &&
        state.columnPreferences[index] !== undefined
      ) {
        return state.columnPreferences[index];
      }

      return null;
    },
    getSelectionPreferencesByEventId: state => id => {
      if (
        state.selectionPreferences !== null &&
        state.selectionPreferences.hasOwnProperty(id)
      ) {
        return state.selectionPreferences[id];
      }

      return null;
    }
  },
  mutations: {
    setSettings(state, settings) {
      Object.assign(state, settings);
    },
    setLocale(state, locale) {
      state.locale = locale;
      localStorage.setItem("settings", JSON.stringify(state));
    },
    setTime(state, time) {
      state.time = time;
      localStorage.setItem("settings", JSON.stringify(state));
    },
    setStatusAutoRefresh(state, autoRefresh) {
      state.status.autoRefresh = autoRefresh;
      localStorage.setItem("settings", JSON.stringify(state));
    },
    toggleFieldPreference(state, id) {
      if (state.fieldPreferences === null) {
        state.fieldPreferences = new Object();
      }

      Vue.set(
        state.fieldPreferences,
        id,
        state.fieldPreferences.hasOwnProperty(id)
          ? !state.fieldPreferences[id]
          : false
      );
      localStorage.setItem("settings", JSON.stringify(state));
    },
    setColumnPreference(state, payload) {
      if (state.columnPreferences === null) {
        state.columnPreferences = new Array();
      }

      Vue.set(state.columnPreferences, payload.index, payload.value);
      localStorage.setItem("settings", JSON.stringify(state));
    },
    setSelectionPreferences(state, payload) {
      if (state.selectionPreferences === null) {
        state.selectionPreferences = new Object();
      }

      Vue.set(state.selectionPreferences, payload.eventId, payload.columns);
      localStorage.setItem("settings", JSON.stringify(state));
    }
  },
  actions: {
    loadSettings({ commit }) {
      if (localStorage.settings !== undefined) {
        commit("setSettings", JSON.parse(localStorage.settings));
      }
    }
  }
};
